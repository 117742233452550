/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  color: #444444;
}

a {
  color: #ffffff;
  text-decoration: none;
}

a:hover {
  color: #ffffff;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Montserrat", sans-serif;
}

p {
  font-family: "Inter", sans-serif;
}

.btn-get-started {
  line-height: 0;
  padding: 25px 40px;
  border-radius: 20px;
  transition: 0.3s;
  color: #ffffff;
  background: #1d2027;
  text-decoration: none;
}

.btn-get-started:hover {
  transform: scale(1.05);
}

.btn-get-started span {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 16px;
}

.btn-get-started i {
  margin-left: 5px;
  font-size: 18px;
  transition: 0.3s;
}

/*--------------------------------------------------------------
  # Sections
  --------------------------------------------------------------*/
section {
  padding: 60px 0;
  overflow: hidden;
}

.section-header {
  padding-bottom: 30px;
}

.section-header h2 {
  font-size: 32px;
  font-weight: 700;
  margin: 0 0 20px 0;
  color: #1d2027;
}

.section-header p {
  padding: 0;
  font-size: 20px;
  line-height: 24.2px;
  font-weight: 400;
  color: rgba(29, 32, 39, 0.8);
}

@media (max-width: 768px) {
  .section-header p {
    font-size: 16px;
    line-height: 19.36px;
  }
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
.header {
  transition: all 0.5s;
  z-index: 997;
  padding: 20px 0;
}

.header.header-scrolled {
  background: #fff;
  padding: 15px 0;
  box-shadow: 0px 2px 20px rgba(1, 41, 112, 0.1);
}

.header .logo {
  line-height: 0;
}

.header .logo img {
  max-height: 40px;
  margin-right: 6px;
}

.header .logo span {
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 1px;
  color: #012970;
  font-family: "Inter", sans-serif;
  margin-top: 3px;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation 
  */
.navbar {
  padding: 0;
}

.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}

.navbar-collapse {
  flex-grow: initial !important;
}

.navbar li {
  position: relative;
}

.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 0px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: #1d2027;
  white-space: nowrap;
  transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}

.navbar a:hover,
.navbar .inview,
.navbar .inview:focus,
.navbar li:hover > a {
  font-weight: 700;
}

.navbar .getstarted,
.navbar .getstarted:focus {
  font-weight: 700;
  background: #1d2027;
  padding: 10px 30px;
  margin-left: 0px;
  border-radius: 20px;
  color: #fff;
  text-decoration: none;
  width: 100%;
  transition: 0.3s;
}

@media (min-width: 992px) {
  .navbar .getstarted,
  .navbar .getstarted:focus {
    margin-left: 15px;
  }
}

.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #1d2027;
}

.navbar .getstarted:hover {
  transform: scale(1.05);
}

/**
  * Mobile Navigation 
  */
.mobile-nav-toggle {
  color: #012970;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}

.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 1200px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}

.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(1, 22, 61, 0.9);
  transition: 0.3s;
}

.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}

.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #012970;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #4154f1;
}

.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
.hero {
  width: 100%;
  height: 100vh;
  background: url(./assets/img/banner-design.webp) top center no-repeat;
  background-size: cover;
}

.hero h1 {
  margin: 0 0 25px 0;
  font-size: 50px;
  font-weight: 700;
  color: #1d2027;
}

.hero h2 {
  color: #444444;
  margin: 15px 0 0 0;
  font-size: 26px;
}

.hero .hero-img {
  text-align: right;
}

#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (min-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}

@media (max-width: 991px) {
  .hero {
    height: auto;
    padding: 120px 0 60px 0;
  }

  .hero .hero-img {
    text-align: center;
    margin-top: 80px;
  }

  .hero .hero-img img {
    width: 80%;
  }
}

@media (max-width: 768px) {
  .hero {
    text-align: center;
  }

  .hero h1 {
    font-size: 32px;
  }

  .hero h2 {
    font-size: 24px;
  }

  .hero .hero-img img {
    width: 100%;
  }
}

/*--------------------------------------------------------------
  # What We Do Section
  --------------------------------------------------------------*/
#trading-system,
#what-we-do {
  /* background: url("../img/facts-bg.jpg") center top no-repeat fixed;
  background-size: cover; */
  background: #fff;
  padding: 60px 0 60px 0;
  position: relative;
}

#trading-system {
  background: #fbfbfb;
}

#trading-system::before,
#what-we-do::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #fff;
  z-index: 9;
}

#trading-system::before {
  background: #fbfbfb;
}

#trading-system .container,
#what-we-do .container {
  position: relative;
  z-index: 10;
}

#trading-system .trading-system-img,
#what-we-do .what-we-do-img {
  text-align: center;
  padding-top: 30px;
}

#trading-system #trading-system-img,
#what-we-do .what-we-do-img #trading-system-img {
  box-shadow: 0px 20px 100px -10px rgba(0, 0, 0, 0.05);
}

/* Default styles for desktop */
.desktop-only {
  display: block;
}

.mobile-only {
  display: none;
}

/**
  * What We Do - Mobile Screen
  */

.what-we-do {
  background-color: #fbfbfb;
}

.what-we-do .content p:last-child {
  margin-bottom: 0;
}

.what-we-do .icon-box {
  padding: 8px 15px 15px 15px;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
  min-height: 260px;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
}

.what-we-do a {
  text-decoration: none;
  color: #101828;
}

.what-we-do .icon-box i {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 0;
  transition: all 0.4s ease-out 0s;
  position: absolute;
  top: 10px; /* Adjust the top position as needed */
  right: 10px; /* Adjust the left position as needed */
}

.what-we-do .icon-box h3 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  letter-spacing: -0.44px;
}

.what-we-do .icon-box p {
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.32px;
}

.what-we-do .icon-boxes .col-md-6:nth-child(1) .icon-box,
.what-we-do .icon-boxes .col-md-6:nth-child(3) .icon-box {
  margin-top: -60px;
}

@media (max-width: 768px) {
  .what-we-do .icon-boxes .col-md-6:nth-child(1) .icon-box,
  .what-we-do .icon-boxes .col-md-6:nth-child(3) .icon-box {
    margin-top: 0px;
  }
  .what-we-do .icon-boxes .col-md-6:nth-child(2) .icon-box,
  .what-we-do .icon-boxes .col-md-6:nth-child(4) .icon-box {
    margin-top: 0px;
  }

  .desktop-only {
    display: none;
  }

  .mobile-only {
    display: block;
  }
}

/*--------------------------------------------------------------
# Why Choose Us Section
--------------------------------------------------------------*/

.why-choose-us-header-text {
  color: #fff;
  font-family: Montserrat;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.why-choose-us-subheader-text {
  color: rgba(255, 255, 255, 0.8);

  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.point-text-purple {
  color: #c7b1ff;
  font-family: Montserrat;
  font-size: 28px;
  width: 648px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 3rem;
  margin-top: 0.5rem;
}

.point-text-white {
  color: #fff;
  font-family: Montserrat;
  font-size: 28px;
  width: 648px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 3rem;
  margin-top: 0.5rem;
}

.purple-circle {
  background-color: #c7b1ff;
  border-radius: 50%;
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  border: 1px solid #c7b1ff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1d2027;
  text-align: center;
  font-family: Montserrat;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
}

.hollow-circle {
  background-color: transparent;
  border-radius: 50%;
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  opacity: 50%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  font-family: Montserrat;
  font-size: 50px;
  font-style: normal;
  font-weight: 500;
}

.ul-text {
  color: rgba(255, 255, 255, 0.8);
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.single-point {
  margin-left: 3rem;
}

.why-choose-us-point {
  margin-bottom: 3rem;
}

.container {
  margin-top: 3rem;
}
@media (max-width: 768px) {
  .why-choose-us-section {
    padding-top: 1.5rem;
  }
  .container {
    margin: 16px;
    margin-top: 0;
  }
  .why-choose-us-header-text {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .why-choose-us-subheader-text {
    font-size: 16px;
  }

  .ul-text {
    font-size: 15px;
  }

  ul ol {
    padding-left: 1rem;
  }

  .point-text-purple {
    font-size: 22px;
    width: 100%;
  }

  .point-text-white {
    font-size: 22px;
    width: 100%;
  }

  .hollow-circle {
    font-size: 40px;
  }

  .purple-circle {
    font-size: 40px;
  }

  .single-point {
    margin-left: 0;
  }

  .why-choose-us {
    margin-bottom: 0;
  }
}

/*--------------------------------------------------------------
# How We Engage Section
--------------------------------------------------------------*/
.how-we-engage {
  padding: 60px 0;
  background: linear-gradient(113deg, rgba(225, 220, 242, 0.5) 0%, rgba(196, 218, 248, 0.5) 100%);
}

.how-we-engage .how-we-engage-item {
  padding: 40px;
  background: #fff;
  height: 100%;
  border-radius: 20px;
  transition: 0.3s;
}
.how-we-engage .how-we-engage-item:hover {
  transform: scale(1.05);
}
.how-we-engage .how-we-engage-item .icon {
  border: 1px solid rgba(29, 32, 39, 0.2);
  border-radius: 50px;
  width: 85px;
  height: 85px;
  position: relative;
  margin-bottom: 50px;
}

.how-we-engage .how-we-engage-item .icon i {
  color: rgba(29, 32, 39, 0.2);
  font-size: 56px;
  transition: ease-in-out 0.3s;
  z-index: 2;
  position: relative;
  bottom: -16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.how-we-engage .how-we-engage-item h3 {
  color: #1d2027;
  font-weight: 700;
  margin: 0 0 20px 0;
  padding-bottom: 8px;
  font-size: 22px;
  position: relative;
  display: inline-block;
  transition: 0.3s;
}

.how-we-engage .how-we-engage-item p {
  font-family: "Montserrat", sans-serif;
  color: rgba(29, 32, 39, 0.8);
  font-size: 16px;
  margin-bottom: 0;
}

.how-we-engage .how-we-engage-item:hover .icon:before {
  border: 3px solid #c7b1ff;
}

/*--------------------------------------------------------------
# What We Build - Home Page
--------------------------------------------------------------*/

.what-we-build {
  background-color: #fbfbfb;
}

.what-we-build .design-bg {
  width: 100%;
  background: url(./assets/img/what-we-build-bg.webp) top center no-repeat;
  background-size: cover;
}

.what-we-build .full-width-background {
  background: conic-gradient(
    from 180deg at 50.06% 47.3%,
    #e1dcf2 22.469563633203506deg,
    rgba(221, 219, 235, 0.73) 109.96802687644958deg,
    #f8deec 155.40122509002686deg,
    #c4daf8 222.42387771606445deg,
    #dedcf2 323.05169105529785deg
  );
}

.what-we-build .content p:last-child {
  margin-bottom: 0;
}

.what-we-build .btn-get-started-secondary {
  color: #1d2027;
  border: 1px solid rgba(29, 32, 39, 0.2);
  background: #f7f7fb;
}

.what-we-build .icon-box {
  padding: 70px 30px;
  box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: all ease-in-out 0.3s;
  background-color: #fff;
  min-height: 260px;
  height: 100%;
  position: relative;
  flex-wrap: wrap;
}

.what-we-build a {
  text-decoration: none;
  color: #1d2027;
}

.what-we-build .icon-box:hover {
  color: #fff;
  background: #1d2027;
}

.what-we-build .icon-box i {
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  font-size: 32px;
  line-height: 0;
  transition: all 0.4s ease-out 0s;
  position: absolute;
  top: 10px; /* Adjust the top position as needed */
  right: 10px; /* Adjust the left position as needed */
}

.what-we-build .icon-box h3 {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 700;
}

.what-we-build .icon-box p {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.7px;
  margin-bottom: 0;
}

.what-we-build .full-width-background .col:nth-child(1),
.what-we-build .full-width-background .col:nth-child(3){
  margin-top: -100px;
}

.what-we-build .full-width-background .col:nth-child(4),
.what-we-build .full-width-background .col:nth-child(6) {
  margin-top: -20px;
  margin-bottom: 20px;
}

.what-we-build .full-width-background .col:nth-child(2) {
  margin-top: -50px;
}

.what-we-build .full-width-background .col:nth-child(5) {
  margin-bottom: -50px;
}


.what-we-build .design-bg .container p {
  margin-bottom: 80px;
}

#build-cards{
  min-height: 220px;
}

@media only screen and (max-width: 992px) {
  .what-we-build .full-width-background .col:nth-child(1){
    margin-top: 0px;
  }

  .what-we-build .full-width-background .col:nth-child(3),
  .what-we-build .full-width-background .col:nth-child(5){
    margin-top: 30px;
  }
  .what-we-build .full-width-background .col:nth-child(6){
    margin-bottom: -30px;
  }

  .what-we-build .full-width-background .col:nth-child(2){
    margin-bottom: 0px;
  }

  #build-cards{
    min-height: 250px;
  }

  .what-we-build .design-bg .container p {
    margin-bottom: 60px;
  }

  #view-all-projects-button {
    margin-top: 70px;
  }
}

@media only screen and (max-width:767px) {
  .what-we-build .full-width-background .col{
    margin-top: 10px !important;
    margin-bottom: 10px !important;
  }

 #build-cards{
    min-height: 0px;
  }

  .what-we-build .design-bg .container p {
    margin-bottom: 40px;
  }

  #view-all-projects-button {
    margin-top: 0px;
  }
}

@media only screen and (max-width:767px) { 
  .what-we-build .design-bg .container p {
    margin-bottom: 0px;
  }
}

/*--------------------------------------------------------------
# Who We Are Section
--------------------------------------------------------------*/
#who-we-are {
  width: 100%;
  background: url(./assets/img/who-we-are-bg.webp) top center no-repeat;
  background-size: cover;
}

.who-we-are-header {
  color: #101828;
  font-family: "Montserrat", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 30px;
}

#who-we-are .card {
  padding: 5px;
  transition: 0.3s;
}

#who-we-are .card:hover {
  transform: scale(1.15);
}

.who-we-are-text {
  color: rgba(16, 24, 40, 0.8);
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.image-name {
  margin-top: 5px;
  color: #101828;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.3px;
}

@media (min-width: 992px) {
  #who-we-are .col-lg-1 {
    width: 14.285714285714285714285714285714% !important;
  }
}

@media (max-width: 768px) {
  .container {
    margin: 16px;
  }
  .who-we-are-header {
    font-size: 28px;
    margin-bottom: 20px;
  }

  .who-we-are-text {
    font-size: 16px;
  }

  .image-name {
    font-size: 15px;
  }
}

@media (max-width: 992px) {
  #who-we-are .px-3 {
    padding-right: 0.3rem !important;
    padding-left: 0.3rem !important;
  }
}

/*--------------------------------------------------------------
# Gallery
--------------------------------------------------------------*/

#gallery {
  width: 100%;
  background: url(./assets/img/gallery-bg.webp), #f7f7fb top center no-repeat;
  background-size: cover;
}

/*
.carousel {
  display: flex;
  transition: transform 0.5s ease;
}

.carousel-item {
  flex: 0 0;
  margin: 0 5px; 
  box-sizing: border-box;
  transition: border 0.5s ease;
} */

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
#contact {
  background-color: #fff;
  width: 100%;
  background: url(./assets/img/contact-bg.webp) top center no-repeat;
  background-size: cover;
}
.contact .info {
  border-radius: 30px;
  border: 1px solid rgba(29, 32, 39, 0.2);
  background: #f9fafb;
  padding: 20px;
  width: 100%;
}

/* .contact .info i {
  font-size: 20px;
  color: #009cea;
  float: left;
  width: 44px;
  height: 44px;
  background: #f2f4f5;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
} */

.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #364146;
}

.contact .info p {
  font-family: "Inter", sans-serif;
  padding: 0 0 0px 25px;
  margin-bottom: 5px;
  font-size: 14px;
  color: rgba(29, 32, 39, 0.8);
}

.contact .php-email-form {
  width: 100%;
  border-right: 2px solid rgba(29, 32, 39, 0.2);
}

.contact .php-email-form .form-group {
  padding-bottom: 5px;
}

.contact .php-email-form input,
.contact .php-email-form textarea {
  margin-top: 5px;
  border-radius: 20px;
  border: 1px solid rgba(29, 32, 39, 0.2);
  background: #f7f7fb;
  box-shadow: none;
  font-size: 14px;
  padding: 23px 20px;
}

.contact .php-email-form input {
  height: 44px;
}

.contact .php-email-form textarea {
  padding: 10px 12px;
}

#contact .info {
  color: #333333;
}

#contact .info i {
  margin-top: 5px;
  font-size: 32px;
  color: #1d2027;
  float: left;
  line-height: 0;
}

#contact .info p {
  padding: 0 0 10px 42px;
  font-size: 16px;
}

#location {
  margin-bottom: 23px;
}

#location h3 {
  font-size: 25px;
  font-weight: 700;
  margin: 0px 0px 0px 20px;
}

.contact .php-email-form .sent-message {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  transition: 0.3s;
  border-radius: 20px;
  background: #18d26e;
  color: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .loading {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  transition: 0.3s;
  border-radius: 20px;
  background: #ffcc00;
  color: #fff;
  text-align: center;
  padding: 15px;
}

.contact .php-email-form .error-message {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 16px;
  transition: 0.3s;
  border-radius: 20px;
  background: #ed3c0d;
  color: #fff;
  text-align: center;
  padding: 15px;
}

@media (min-width: 992px) {
  .contact .php-email-form {
    padding-right: 3.5rem;
  }

  .contact #contact-info {
    padding-left: 2.5rem;
  }
}

@media (max-width: 991px) {
  .contact .php-email-form {
    width: 100%;
    border-right: 0px solid rgba(29, 32, 39, 0.2);
    border-bottom: 2px solid rgba(29, 32, 39, 0.2);
    padding-bottom: 20px;
  }

  .contact #contact-info {
    padding-top: 20px;
  }

  .contact {
    padding-top: 0px;
  }
}

@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer ul a {
  transition: 0.2s;
  text-decoration: none;
  color: #fff;
}

#footer ul a:hover,
#footer .active:hover,
#footer li:hover > a {
  font-weight: 700;
}

footer {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.footer {
  color: #ffffff;
  font-size: 14px;
}

.footer .footer-legal {
  background: #1d2027;
  font-family: "Inter", sans-serif;
  padding: 5rem 0px 5rem 0px !important;
}

.footer .footer-legal .credits {
  padding-top: 4px;
  font-size: 13px;
  color: #ffffff;
}

.footer .footer-legal .social-links a {
  font-size: 18px;
  display: inline-block;
  color: #ffffff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}

.footer .footer-legal .social-links a:hover {
  transform: scale(1.1);
  text-decoration: none;
}

@media (max-width: 768px) {
  footer {
    flex-direction: column;
  }
  .nav {
    flex-direction: column;
    align-items: center;
  }

  .nav li {
    margin: 10px 10px 10px 0px;
  }
}

/*--------------------------------------------------------------
# Projects Page
--------------------------------------------------------------*/
/* Universal CSS */

.article-link:hover{
color: #101828;
text-decoration: underline;
}

#project-cards{
  min-height: 220px;
}

#project-list-card {
  border-radius: 30px;
  transition: 0.3s;
}
 
#project-list-card:hover {
  transform: scale(1.05);
}

#project-list-link {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  padding-left: 4px;
  font-size: 14px;
}

.project-header-container {
  padding: 100px 0 30px 0;
  background-color: #1d2027;
}

@media only screen and (max-width: 768px) {
  .project-header-container {
    padding: 80px 0 1px 0;
    position: relative;
    background-color: #1d2027;
  }
}

.project-header h2 {
  padding: 0px;
  font-weight: 700;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

.project-category-container {
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-category {
  height: 51px;
  border-radius: 50px;
  background: #f7f7fb;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-item {
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #1d2027;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 600;
}

.category-list {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
}

.category-list-item {
  display: flex;
  padding: 3px 8px;
  font-family: "Inter", sans-serif;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 15px;
  background: white;
  margin: 5px 5px 5px 0px;
}

.category-name {
  color: #1d2027;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.project-details-item-headline-tag {
  color: rgba(16, 24, 40, 0.8);
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 700;
}

.project-details-item-headline-list {
  color: #101828;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
}

.project-details-item-content-tag {
  color: rgba(16, 24, 40, 0.8);
  font-family: "Inter", sans-serif;
  font-size: 20px;
  font-weight: 600;
}

.project-details-item-content-list {
  color: #101828;
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.6;
}

.project-details-item-paragraph-text {
  color: rgba(16, 24, 40);
  font-family: "Inter", sans-serif;
  font-size: 18px;
  font-weight: 400;
}

.project-details-item-diagram {
  width: 100%; /* Ensure the image takes up the full width of its container */
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-details-item-diagram img {
  max-width: 100%;
  height: auto;
}

.project-details-action {
  display: inline-block;
  border-radius: 50%;
  background-color: #cccccc;
  padding: 10px;
  cursor: pointer;
}

#middle-col{
  margin-top: 2rem;
}

/* Big screen */
@media only screen and (min-width: 1200px) {



  .category-item {
    padding: 10px 22px;
  }

  .project-details-item-headline {
    margin-top: 30px;
  }

  .project-details-item-highlight {
    margin-top: 30px;
  }

  .project-details-item-paragraph {
    margin-top: 30px;
  }
  
  .project-details-item-heading {
    margin-top: 30px;
  }
}

/* Laptops */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  /* Styles for large screens go here */

  #project-cards{
    min-height: 245px;
  }

  .category-item {
    font-size: 15px;
  }

  .category-list-item {
    padding: 3px 6px;
    font-size: 8px;
  }

  .category-name {
    text-wrap: wrap;
    font-size: 15px;
  }

  .project-details-item-headline {
    margin-top: 30px;
  }

  .project-details-item-highlight {
    margin-top: 30px;
  }

  .project-details-item-paragraph {
    margin-top: 30px;
  }

  .project-details-item-heading {
    margin-top: 30px;
  }
}
/* tablet */
@media only screen and (min-width: 680px) and (max-width: 991px) {
  /* Styles for tablet screens go here */

  #project-cards{
    min-height: 250px;
  }

  .project-category {
    height: 100px;
    background: none;
    flex-wrap: wrap;
    width: 700px;
  }

  .category-item {
    font-size: 12px;
    margin: 5px;
  }

  .category-list-item {
    padding: 2px 7px;
    font-size: 10px;
  }

  .category-name {
    text-wrap: wrap;
    font-size: 14px;
  }

  .project-details-item-headline {
    margin-top: 20px;
  }

  .project-details-item-headline-tag {
    font-size: 17px;
  }

  .project-details-item-headline-list {
    font-size: 15px;
  }

  .project-details-item-highlight {
    margin-top: 20px;
  }

  .project-details-item-paragraph {
    margin-top: 20px;
  }

  .project-details-item-heading {
    margin-top: 20px;
  }

  .project-details-item-content-tag {
    font-size: 17px;
  }
  .project-details-item-content-list {
    font-size: 15px;
  }

  .project-details-item-paragraph-text {
    font-size: 15px;
  }
}
/* big screen mobile */
@media only screen and (min-width: 470px) and (max-width: 679px) {
  /* Styles for tablet screens go here */
  .project-category {
    height: 100px;
    flex-wrap: wrap;
    background: none;
    width: 700px;
  }

  .category-item {
    font-size: 12px;
    margin: 5px;
  }

  .category-list-item {
    padding: 2px 8px;
    font-size: 12px;
  }

  .category-name {
    text-wrap: wrap;
    font-size: 14px;
  }

  .project-details-item-headline {
    margin-top: 20px;
  }

  .project-details-item-headline-tag {
    font-size: 17px;
  }

  .project-details-item-headline-list {
    font-size: 15px;
  }

  .project-details-item-highlight {
    margin-top: 20px;
  }

  .project-details-item-paragraph {
    margin-top: 20px;
  }
  
  .project-details-item-heading {
    margin-top: 20px;
  }

  .project-details-item-content-tag {
    font-size: 17px;
  }
  .project-details-item-content-list {
    font-size: 15px;
  }

  .project-details-item-paragraph-text {
    font-size: 15px;
  }
}

@media only screen and (max-width: 367px) {

  #project-cards{
    min-height: 245px;
  }

  .project-category-container {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 306px) {

  .project-category-container {
    margin-bottom: 75px;
  }
}

/* mobile */
@media only screen and (max-width: 469px) {
  /* Styles for tablet screens go here */

  #project-list-link{
    font-size: 14px;
  }

  .project-category {
    flex-wrap: wrap;
    height: 100px;
    background: none;
    width: 700px;
  }

  .category-item {
    font-size: 12px;
    margin: 5px;
    padding: 8px 19px;
  }

  .category-list-item {
    padding: 2px 6px;
    font-size: 11px;
  }

  .category-name {
    text-wrap: wrap;
    font-size: 14px;
  }

  .project-details-item-headline {
    margin-top: 20px;
  }

  .project-details-item-headline-tag {
    font-size: 15px;
  }

  .project-details-item-headline-list {
    font-size: 13px;
    ul {
      padding-left: 1rem;
    }
  }

  .project-details-item-highlight {
    margin-top: 20px;
  }

  .project-details-item-paragraph {
    margin-top: 20px;
  }

  .project-details-item-heading {
    margin-top: 20px;
  }

  .project-details-item-content-tag {
    font-size: 15px;
  }
  .project-details-item-content-list {
    font-size: 13px;
    ul {
      padding-left: 1rem;
    }
  }

  .project-details-item-paragraph-text {
    font-size: 13px;
  }

  .project-details-item-diagram {
    padding: 0px;
    margin-top: 20px;
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs .page-header {
  padding: 80px 0 35px 0;
  position: relative;
  background-color: #1d2027;
}

.breadcrumbs .page-header h1 {
  font-size: 32px;
  font-weight: 700;
  color: #fff;
  font-family: "Montserrat", sans-serif;
}

@media only screen and (max-width: 768px) {
  .breadcrumbs .page-header {
    padding: 120px 0 20px 0;
    position: relative;
    background-color: #1d2027;
  }
}

/* mobile */
@media only screen and (max-width: 469px) {
  .breadcrumbs .page-header {
    padding: 120px 0 20px 0;
    position: relative;
    background-color: #1d2027;
  }
}
/*--------------------------------------------------------------
# Dark Theme
--------------------------------------------------------------*/
.dark-theme .navbar {
  background-color: #1d2027 !important;
  color: #fff;
}

.dark-theme .navbar .getstarted,
.dark-theme .navbar .getstarted:focus {
  background: #fff;
  color: #1d2027;
}

.dark-theme .navbar a,
.dark-theme .navbar a:focus {
  color: #fff;
}

.dark-theme .navbar-toggler {
  color: #fff;
  background-color: #fff !important;
}

.dark-theme .navbar-nav .nav-link.active,
.dark-theme .navbar-nav .nav-link.show {
  color: #fff !important;
}